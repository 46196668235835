import About from "../pages/about/About";
import Projects from "../pages/projects/Projects";
// import Novels from "../pages/novels/Novels";
import ResearchPublications from "../pages/research-publications/ResearchPublications";

export enum Path {
	About = "about",
	// Novels = "novels",
	Projects = "projects",
	ResearchPublications = "research-publications",
};

export const SubPathTitle = "title";

export const PathListMenu = [
	// Path.Novels,
	Path.ResearchPublications,
	Path.Projects,
	Path.About
];

export function PathToPage(path: Path) {
	switch(path) {
		case Path.About:
			return <About />;
		//case Path.Novels:
		//	return <Novels />;
		case Path.Projects:
			return <Projects />;
		case Path.ResearchPublications:
			return <ResearchPublications />;
		default:
			return <></>;
	}
}
