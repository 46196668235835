import { useTranslation } from "react-i18next";
import style from "./ProjectEntry.module.scss";

interface IProjectEntryProps {
	id: string,
	name: string,
	url: string,
};

export default function ProjectEntry({id, name, url}: IProjectEntryProps) {
	const { t } = useTranslation();

	const descriptionPath = ["projects", "details", id, "description"].join(".");

	return (
		<div>
			<p className={style["name"]}>{name}</p>
			<a href={url}>{url}</a>
			<p className={style["description"]}>{t(descriptionPath)}</p>
		</div>
	);
};
