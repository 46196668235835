import { useTranslation } from "react-i18next";
import Themes from "../themes/Themes";
import useTheme from "../themes/useTheme";
import MenuItem from "./MenuItem";
import { useCallback } from "react";

interface ISubMenuTheme {
	setMenuOpen: (a: Boolean) => void,
};

export default function SubMenuTheme({setMenuOpen: setMenuOuvert}: ISubMenuTheme) {
	const [theme, setTheme] = useTheme();
	const { t } = useTranslation();

	const changeTheme = useCallback(() => {
		setTheme(theme === Themes.Dark? Themes.Light : Themes.Dark);
	}, [setTheme, theme]);

	return <MenuItem
		callbackArgument={undefined}
		key={"theme"}
		setMobileMenuVisible={setMenuOuvert}
		text={t("change-theme")}
		onClick={changeTheme}
	/>;
}
