import ProjectEntry from "./ProjectEntry";
import listOfProjects from "./projects.json";
import style from "./ProjectEntry.module.scss";

export default function Projects() {
	return (
		<main className={style["content"]}>
			{listOfProjects.map((o) => ProjectEntry(o))}
		</main>
	);
};
